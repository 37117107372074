import { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import { IProps } from './type';
import { Button, Typography } from 'antd';
import XmarkSolidW from '../../assets/icons/xmark-solid.svg';
import Icon from '../Icon';

const ModalDialog: FC<PropsWithChildren<IProps>> = (props) => {
  if (!props.open) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modal} ${props.open && styles.open}`}>
        <div className={styles.modalHeader}>
          <div>
            <h3 className={styles.title}>Dikkat</h3>
          </div>
          <div className={styles.closeButton} onClick={props.handleCancel}>
            <Icon source={XmarkSolidW} />
          </div>
        </div>
        <div className={styles.modalBody}>
          {props.default ? (
            <Typography>Bu profil varsayılan bir rolle ilişkilendirildiği için silinemez.</Typography> // todo : daha anlamlı bir mesaj yaz.
          ) : (
            <>
              <Typography>
                Silmek üzere olduğunuz ‘{props.role}’ rolüne bağlı {props.count}{' '}
                kullanıcı bulunmaktadır. Silme işlemine devam etmeniz halinde bu
                role sahip kullanıcılar da bu durumdan etkilenecektir.
              </Typography>
              <Typography>İşleme devam etmek istiyor musunuz ?</Typography>
            </>
          )}
        </div>
        <div className={styles.modalFooter}>
          <Button className={styles.cancelButton} onClick={props.handleCancel}>
            İptal
          </Button>

          {!props.default && (<Button className={styles.submitButton} onClick={props.handleOk}>
            Devam Et
          </Button>) }
          
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
