import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import { IDataSource, IInitialValues } from './type';

export const prepareFetchParameters = (
  search?: ISearch,
  sort?: ISort,
  page?: IPage,
): string => {
  const params = new URLSearchParams();

  page?.size && params.append('limit', page?.size.toString());
  typeof page?.number === 'number' &&
    params.append('offset', (page.size * page.number).toString());
  search?.key && search?.value && params.append(search.key, search.value);
  sort?.key &&
    sort.order &&
    params.append(
      'ordering',
      sort.order === 'ascend' ? sort.key : `-${sort.key}`,
    );

  return params.size ? `?${params.toString()}` : '';
};

export const cleanupAfterSubmit = (
  setIsModalOpen: (open: boolean) => void,
  setSelectedUser: (policy?: IDataSource) => void,
  formikReset: () => void,
  handleFetchData: () => void,
) => {
  setIsModalOpen(false);
  setSelectedUser(undefined);
  formikReset();
  handleFetchData();
};

export const setFormForSelectedUser = (
  selectedItem: IDataSource,
): IInitialValues => {
  return {
    id: selectedItem.id,
    email: selectedItem.email,
    first_name: selectedItem.first_name,
    last_name: selectedItem.last_name,
    position: selectedItem.position,
    department: selectedItem.department,
    phone_number: selectedItem.phone_number,
    address: selectedItem.address,
    role: selectedItem.role.id,
    organization: selectedItem.organization,
    deactivation_period_months: selectedItem.deactivation_period_months,
    created_at: selectedItem.created_at,
    created_by: selectedItem.created_by,
  };
};
