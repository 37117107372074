import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  upload_files: [],
  email_permission: false,
  organization: 0,
  policy_number: undefined,
  start_date: '',
  end_date: '',
  description: '',
  branch: '',
  recent_status: '',
};
