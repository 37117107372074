import { FC, useEffect, useRef } from 'react';
import { DatePicker, Flex, Input, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  ErrorMessage,
  LabelTitle,
  MailCheckbox,
  ModalDialog,
  StatusDropdown,
  UploadButton,
} from '../';
import { IProps } from './type';
import styles from './index.module.scss';
import globalStyles from '../../assets/styles/index.module.scss';
import { CheckSibaOrganization } from '../../helpers/Organization';
import { useAppSelector } from '../../store';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const DamageNotificationModal: FC<IProps> = (props) => {
  const { getFieldProps, setFieldValue, values, errors, touched } =
    props.formik;
  const uploadInput = useRef<HTMLInputElement | null>(null);
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  useEffect(() => {
    if (values.damage_type === 'domestic') setFieldValue('country', 'TR');
    else if (values.damage_type === 'abroad') setFieldValue('city', '');
    else if (values.damage_type === 'transportation') {
      setFieldValue('city', '');
      setFieldValue('country', '');
      setFieldValue('location_code', '');
    }
  }, [values.damage_type, setFieldValue]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            <Select
              id="organization"
              value={
                props.organizations.find(
                  (item) => item.id === (values.organization || organization),
                )?.name || undefined
              }
              placeholder="Firma Seçiniz"
              style={{ width: 200 }}
              className={styles.input}
              disabled={!CheckSibaOrganization()}
              options={props.organizations.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              onChange={(value) => setFieldValue('organization', value)}
            />
            {touched.organization && errors.organization && (
              <ErrorMessage>{errors.organization}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Hasar Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                placeholder="Hasar Tipi"
                options={props.damageTypes.map((dt) => ({
                  value: dt.key,
                  label: dt.value,
                }))}
                value={values.damage_type || undefined}
                onChange={(value) => setFieldValue('damage_type', value)}
              />
              {touched.damage_type && errors.damage_type && (
                <ErrorMessage>{errors.damage_type}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                placeholder="Ülke"
                disabled={
                  values.damage_type === 'transportation' ||
                  values.damage_type === 'domestic'
                }
                options={props.countries.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.country || undefined}
                onChange={(value) => setFieldValue('country', value)}
              />
              {touched.country && errors.country && (
                <ErrorMessage>{errors.country}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                disabled={
                  values.damage_type === 'transportation' ||
                  values.damage_type === 'abroad'
                }
                placeholder="Şehir"
                options={props.cities.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.city || undefined}
                onChange={(value) => setFieldValue('city', value)}
              />
              {touched.city && errors.city && (
                <ErrorMessage>{errors.city}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Input
                disabled={values.damage_type === 'transportation'}
                placeholder="Lokasyon Kodu"
                {...getFieldProps('location_code')}
              />
              {touched.location_code && errors.location_code && (
                <ErrorMessage>{errors.location_code}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                placeholder="Hasar Branşı"
                options={props.branches.map((b) => ({
                  value: b.key,
                  label: b.value,
                }))}
                value={values.branch || undefined}
                onChange={(value) => setFieldValue('branch', value)}
              />
              {touched.branch && errors.branch && (
                <ErrorMessage>{errors.branch}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <DatePicker
                placeholder="Hasar Tarihi"
                format="YYYY-MM-DD"
                value={
                  dayjs(values.damage_date).isValid()
                    ? dayjs(values.damage_date)
                    : null
                }
                onChange={(date) =>
                  setFieldValue(
                    'damage_date',
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ'),
                  )
                }
              />
              {touched.damage_date && errors.damage_date && (
                <ErrorMessage>{errors.damage_date}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Input
                id="pending_loss"
                name="pending_loss"
                value={values.pending_loss}
                placeholder="Muallak Hasar Bedeli"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(
                    'pending_loss',
                    e.target.value.replace(/[^0-9.,]/g, ''),
                  );
                }}
              />
              {touched.pending_loss && errors.pending_loss && (
                <ErrorMessage>{errors.pending_loss}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneQuarter}>
              <Select
                placeholder="Değer Birimi"
                options={props.currencies.map((c) => ({
                  value: c.key,
                  label: c.value,
                }))}
                value={values.currency || undefined}
                onChange={(value) => setFieldValue('currency', value)}
              />
              {touched.currency && errors.currency && (
                <ErrorMessage>{errors.currency}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <Flex vertical gap="small">
            <LabelTitle>Dosya Bilgileri</LabelTitle>
            <Typography className={styles.description}>
              * Bu alandaki bilgiler Siba Sigorta tarafından doldurulacaktır.
            </Typography>
          </Flex>
          <Flex justify="space-between">
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Dosya No"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('policy_number')}
              />
              {touched.policy_number && errors.policy_number && (
                <ErrorMessage>{errors.policy_number}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Eksper"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('expert')}
              />
              {touched.expert && errors.expert && (
                <ErrorMessage>{errors.expert}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={globalStyles.widthOneThird}>
              <Input
                placeholder="Raportör"
                disabled={props.type === 'CREATE'}
                {...getFieldProps('reporter')}
              />
              {touched.reporter && errors.reporter && (
                <ErrorMessage>{errors.reporter}</ErrorMessage>
              )}
            </Flex>
          </Flex>
          <UploadButton
            fileName={values.upload_files?.[0]?.name}
            uploadRef={uploadInput}
            onChange={props.handleUploadFile}
          />
          {props.type === 'EDIT' && (
            <Flex justify="space-between">
              <LabelTitle>Durum Bilgisi</LabelTitle>
              <StatusDropdown
                status={values.recent_status}
                statusChoices={props.statusChoices}
                onChange={(value) => setFieldValue('recent_status', value)}
              />
            </Flex>
          )}
        </Flex>
        <Flex vertical gap="small">
          <LabelTitle>Açıklama</LabelTitle>
          <Flex vertical>
            <Input.TextArea
              placeholder="Açıklama"
              {...getFieldProps('description')}
              rows={3}
            />
            {touched.description && errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Typography className={styles.time}>
          {dayjs().format('YYYY-MM-DD - HH:mm')}
        </Typography>
        {props.type === 'EDIT' && (
          <MailCheckbox
            checked={values.email_permission}
            onChange={(e: CheckboxChangeEvent) =>
              setFieldValue('email_permission', e.target.checked)
            }
          />
        )}
      </Flex>
    </ModalDialog>
  );
};

export default DamageNotificationModal;
