import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

export const getItems = (
  handleClickResetPassword: () => void,
  handleClickLogout: () => void,
) => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link onClick={handleClickResetPassword} to="#">
          Şifre Değiştirme
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link onClick={handleClickLogout} to="#">
          Çıkış Yap
        </Link>
      ),
    },
  ];

  return items;
};
