import { FC } from 'react';
import { Select } from 'antd';
import styles from './index.module.scss';
import { IProps } from './type';

const StatusDropdown: FC<IProps> = ({ status, onChange, statusChoices }) => {
  return (
    <Select
      value={status}
      style={{ width: 175 }}
      className={styles[`dropdown-${status}`]}
      options={statusChoices.map((sc) => ({
        value: sc.key,
        label: sc.value,
      }))}
      onChange={onChange}
    />
  );
};

export default StatusDropdown;
