export const downloadFile = async (url: string): Promise<void> => {
  try {
    const urlObj = new URL(url);
    const response = await fetch(url);
    const blob = await response.blob();

    const downloadUrl = URL.createObjectURL(blob);

    const urlSegments = urlObj.pathname.split('/');
    const fileName = urlSegments[urlSegments.length - 1];

    const link = document.createElement('a');
    link.href = downloadUrl;

    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};
