import { FC, PropsWithChildren } from 'react';
import { Button } from 'antd';
import { useAppSelector } from '../../store';
import { AuthorizedButtonProps } from './type';

const AuthorizedButton: FC<PropsWithChildren<AuthorizedButtonProps>> = ({
  action,
  model,
  className,
  onClick,
  children,
  size,
  danger,
}) => {
  const permissions = useAppSelector((state) => state.userReducer.permissions);
  const hasPermission = permissions.some(
    (permission) => permission.model === model && permission.action === action,
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <Button size={size} danger={danger} className={className} onClick={onClick}>
      {children}
    </Button>
  );
};

export default AuthorizedButton;
