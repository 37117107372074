import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  email: '',
  first_name: '',
  last_name: '',
  position: '',
  department: '',
  phone_number: '',
  address: '',
  role: 0,
  organization: 0,
  deactivation_period_months: 12,
  created_at: '',
  created_by: '',
};
