import { FC, PropsWithChildren } from 'react';
import styles from './index.module.scss';
import { IProps } from './type';
import { Button } from 'antd';
import XmarkSolidW from '../../assets/icons/xmark-solid.svg';
import Icon from '../Icon';

const ModalDialog: FC<PropsWithChildren<IProps>> = ({
  title,
  subTitle,
  open,
  isButtonsDisplayed = true,
  handleOk,
  handleCancel,
  children,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={`${styles.modal} ${open && styles.open}`}>
        <div className={styles.modalHeader}>
          <div>
            <h3 className={styles.title}>{title}</h3>
            {subTitle && <h6 className={styles.subTitle}>{subTitle}</h6>}
          </div>
          <div className={styles.closeButton} onClick={handleCancel}>
            <Icon source={XmarkSolidW} />
          </div>
        </div>
        <div className={styles.modalBody}>
          {children}
          {isButtonsDisplayed && (
            <div className={styles.modalFooter}>
              <Button className={styles.cancelButton} onClick={handleCancel}>
                İptal
              </Button>
              <Button className={styles.submitButton} onClick={handleOk}>
                Kaydet
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalDialog;
