import { FC } from 'react';
import { Checkbox, Flex } from 'antd';
import { IProps } from './type';
import styles from './index.module.scss';

const MailCheckbox: FC<IProps> = ({ checked, onChange }) => {
  return (
    <Flex gap="small" align="flex-start">
      <Checkbox checked={checked} onChange={onChange} />
      <div className={styles.text}>
        Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Poliçe Bilgilerini
        Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
      </div>
    </Flex>
  );
};

export default MailCheckbox;
