import { FC } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import { PrivateRoute } from './components';
import {
  Login,
  Policy,
  ExpeditionCertificate,
  DamageManagement,
  ProfileSettings,
  UserSettings,
  DomesticInsurance,
  AbroadInsurance,
  OtherInsurance,
  MotorInsurance,
  OrganizationSettings,
  ResetPassword,
} from './pages';
import WithAuthorization from './WithAuthorization';

const App: FC = () => {
  const router = createBrowserRouter([
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'reset-password',
      element: <ResetPassword />,
    },
    {
      path: '/',
      element: <PrivateRoute />,
      children: [
        {
          path: 'freight-insurance',
          children: [
            {
              path: 'policy',
              element: (
                <WithAuthorization model="transportationpolicy">
                  <Policy />
                </WithAuthorization>
              ),
            },
            {
              path: 'expedition-certificate',
              element: (
                <WithAuthorization model="expeditioncertificate">
                  <ExpeditionCertificate />
                </WithAuthorization>
              ),
            },
          ],
        },
        {
          path: 'damage-management',
          element: (
            <WithAuthorization model="expeditioncertificate">
              <DamageManagement />
            </WithAuthorization>
          ),
        },
        {
          path: 'main-insurances',
          children: [
            {
              path: 'domestic-insurance',
              element: (
                <WithAuthorization model="domesticinsurance">
                  <DomesticInsurance />
                </WithAuthorization>
              ),
            },
            {
              path: 'abroad-insurance',
              element: (
                <WithAuthorization model="abroadinsurance">
                  <AbroadInsurance />
                </WithAuthorization>
              ),
            },
            {
              path: 'other-insurance',
              element: (
                <WithAuthorization model="otherinsurance">
                  <OtherInsurance />
                </WithAuthorization>
              ),
            },
          ],
        },
        {
          path: 'user-profiles-and-authorisation',
          children: [
            {
              path: 'profile-settings',
              element: (
                <WithAuthorization model="extendedgroup">
                  <ProfileSettings />
                </WithAuthorization>
              ),
            },
            {
              path: 'user-settings',
              element: (
                <WithAuthorization model="user">
                  <UserSettings />
                </WithAuthorization>
              ),
            },
            {
              path: 'organization-settings',
              element: (
                <WithAuthorization model="organization">
                  <OrganizationSettings />
                </WithAuthorization>
              ),
            },
          ],
        },
        {
          path: 'motor-insurance',
          element: (
            <WithAuthorization model="motorinsurance">
              <MotorInsurance />
            </WithAuthorization>
          ),
        },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/" replace />,
    },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
