import { IDataSource, IOrganization } from './type';
import { Flex, Popconfirm, Select, Tag } from 'antd';
import { AuthorizedIcon, Icon } from '../../../components';
import TrashSolidBlack from '../../../assets/icons/trash-solid-b-black.svg';
import PenToSquareSolidBlack from '../../../assets/icons/pen-to-square-solid-w-black.svg';
import FilePdfSolidBlack from '../../../assets/icons/file-pdf-solid-black.svg';
import EyeRegularWBlack from '../../../assets/icons/eye-regular-w-black.svg';
import Variant99Black from '../../../assets/icons/variant99-black.svg';
import styles from './index.module.scss';
import { status_colors } from '../../../assets/conts';
import { downloadFile } from '../../../helpers/File';
import { IEnum } from '../../../services/type';
import { getValueFromEnum } from '../../../utils/functions';
import { AppDispatch } from '../../../store';
import { tableActions } from '../../../store/reducers/tableReducer';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  handleClickEye: (id: number) => void,
  handleOpenEditModal: (id: number) => void,
  handleClickDelete: (id: number) => Promise<void>,
  handleClickCancel: (id: number) => Promise<void>,
  organizations: IOrganization[],
  branches: IEnum[],
  statusChoices: IEnum[],
  dispatch: AppDispatch,
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'organization',
    key: 'organization',
    searchable: true,
    sorter: (a, b) => a.organization - b.organization,
    render: (_, record) => (
      <div>
        {organizations.find((item) => item.id === record.organization)?.name}
      </div>
    ),
  },
  {
    title: 'Poliçe No',
    dataIndex: 'policy_number',
    key: 'policy_number',
    sorter: (a, b) => a.policy_number - b.policy_number,
  },
  {
    title: 'Branş',
    dataIndex: 'branch',
    key: 'branch',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Branş seçin"
          optionFilterProp="children"
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            confirm();
            dispatch(
              tableActions.changeTableSearch({
                key: 'branch',
                value: value as string,
              }),
            );
          }}
          options={branches.map((item) => ({
            label: getValueFromEnum(branches, item.key) || '',
            value: item.key,
          }))}
        />
      </div>
    ),
    onFilter: (value, record) => record.branch === value,
    sorter: (a, b) => a.branch.localeCompare(b.branch),
    render: (_: unknown, record: IDataSource) => (
      <div>{getValueFromEnum(branches, record.branch)}</div>
    ),
  },
  {
    title: 'Başlangıç Tarihi',
    dataIndex: 'start_date',
    key: 'start_date',
    sorter: (a, b) => a.start_date.localeCompare(b.start_date),
  },
  {
    title: 'Bitiş Tarihi',
    dataIndex: 'end_date',
    key: 'end_date',
    sorter: (a, b) => a.end_date.localeCompare(b.end_date),
  },
  {
    title: 'Durum',
    dataIndex: 'recent_status',
    key: 'recent_status',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Durum seçin"
          optionFilterProp="children"
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            confirm();
            dispatch(
              tableActions.changeTableSearch({
                key: 'recent_status',
                value: value as string,
              }),
            );
          }}
          options={statusChoices.map((item) => ({
            label: getValueFromEnum(statusChoices, item.key) || '',
            value: item.key,
          }))}
        />
      </div>
    ),
    onFilter: (value, record) => record.recent_status === value,
    render: (_: unknown, record: IDataSource) => (
      <Tag color={status_colors[record.recent_status]}>
        {getValueFromEnum(statusChoices, record.recent_status)}
      </Tag>
    ),
  },
  {
    title: 'Ek Dosya',
    dataIndex: 'additional_files',
    key: 'additional_files',
    render: (_: unknown, record: IDataSource) => {
      if (record.additional_files.length)
        return (
          <Popconfirm
            title={
              <div className={styles.popconfirmTitle}>
                Ek dosyayı indirmek istiyor musunuz?
              </div>
            }
            overlayInnerStyle={{
              width: 150,
              borderColor: '#E59500',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            okText="İndir"
            cancelText="İptal"
            icon={null}
            color="#FAFAFA"
            okButtonProps={{
              style: { backgroundColor: '#E59500', width: '45%' },
            }}
            cancelButtonProps={{
              style: { color: '#E59500', borderColor: '#E59500', width: '45%' },
            }}
            onConfirm={() =>
              downloadFile(
                record.additional_files[record.additional_files.length - 1]
                  .file,
              )
            }
          >
            <Icon source={FilePdfSolidBlack} />
          </Popconfirm>
        );
      else return <></>;
    },
  },
  {
    title: 'İşlem',
    dataIndex: 'action',
    key: 'action',
    render: (_: unknown, record: IDataSource) => (
      <Flex justify="flex-end" gap={14}>
        <Icon
          source={EyeRegularWBlack}
          onClick={() => record?.id && handleClickEye(record.id)}
        />
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              İptal işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickCancel(record.id)}
        >
          <Icon source={Variant99Black} />
        </Popconfirm>
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              Silme işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickDelete(record.id)}
        >
          <AuthorizedIcon
            action="delete"
            model="otherinsurance"
            source={TrashSolidBlack}
          />
        </Popconfirm>
        <AuthorizedIcon
          action="change"
          model="otherinsurance"
          source={PenToSquareSolidBlack}
          onClick={() => record?.id && handleOpenEditModal(record.id)}
        />
      </Flex>
    ),
  },
];
