import { Flex, Popconfirm, Tag, Select, Tooltip } from 'antd';
import { AuthorizedIcon, Icon } from '../../components';
import TrashSolidBlack from '../../assets/icons/trash-solid-b-black.svg';
import PenToSquareSolidBlack from '../../assets/icons/pen-to-square-solid-w-black.svg';
import EyeRegularWBlack from '../../assets/icons/eye-regular-w-black.svg';
import Variant99Black from '../../assets/icons/variant99-black.svg';
import Subtract from '../../assets/icons/Subtract.svg';
import { IDataSource, IOrganization } from './type';
import styles from './index.module.scss';
import { status_colors } from '../../assets/conts';
import dayjs from 'dayjs';
import { IEnum } from '../../services/type';
import { getValueFromEnum } from '../../utils/functions';
import { AppDispatch } from '../../store';
import { tableActions } from '../../store/reducers/tableReducer';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  handleClickEye: (id: number) => void,
  handleClickDelete: (id: number) => Promise<void>,
  handleClickCancel: (id: number) => Promise<void>,
  handleOpenEditModal: (id: number) => Promise<void>,
  handleClickRemoveParent: (parent: number, id: number) => Promise<void>,
  organizations: IOrganization[],
  branches: IEnum[],
  cities: IEnum[],
  countries: IEnum[],
  currencies: IEnum[],
  damageTypes: IEnum[],
  statusChoices: IEnum[],
  dispatch: AppDispatch,
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'organization',
    key: 'organization',
    searchable: true,
    sorter: (a, b) => a.organization - b.organization,
    render: (_, record) => (
      <div>
        {organizations.find((item) => item.id === record.organization)?.name}
      </div>
    ),
  },
  {
    title: 'Dosya No',
    dataIndex: 'policy_number',
    key: 'policy_number',
    sorter: (a, b) => a.policy_number - b.policy_number,
  },
  {
    title: 'Bağlı Dosya No',
    dataIndex: 'parent',
    key: 'parent',
    sorter: (a, b) => a.parent - b.parent,
  },
  {
    title: 'Hasar Tipi',
    dataIndex: 'damage_type',
    key: 'damage_type',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Hasar Tipi seçin"
          optionFilterProp="children"
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            confirm();
            dispatch(
              tableActions.changeTableSearch({
                key: 'damage_type',
                value: value as string,
              }),
            );
          }}
          options={damageTypes.map((item) => ({
            label: getValueFromEnum(damageTypes, item.key) || '',
            value: item.key,
          }))}
        />
      </div>
    ),
    onFilter: (value, record) => record.damage_type === value,
    sorter: (a, b) => a.damage_type.localeCompare(b.damage_type),
    render: (_: unknown, record: IDataSource) => (
      <Tag className={styles[`${record.damage_type}Tag`]}>
        {getValueFromEnum(damageTypes, record.damage_type)}
      </Tag>
    ),
  },
  {
    title: 'Branş',
    dataIndex: 'branch',
    key: 'branch',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Branş seçin"
          optionFilterProp="children"
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            confirm();
            dispatch(
              tableActions.changeTableSearch({
                key: 'branch',
                value: value as string,
              }),
            );
          }}
          options={branches.map((item) => ({
            label: getValueFromEnum(branches, item.key) || '',
            value: item.key,
          }))}
        />
      </div>
    ),
    onFilter: (value, record) => record.branch === value,
    sorter: (a, b) => a.branch.localeCompare(b.branch),
    render: (_: unknown, record: IDataSource) => (
      <div>{getValueFromEnum(branches, record.branch)}</div>
    ),
  },
  {
    title: 'Hasar Yeri',
    dataIndex: 'country',
    key: 'country',
    sorter: (a, b) => a.country.localeCompare(b.country),
    render: (_: unknown, record: IDataSource) => (
      <div>{`${record.country ? getValueFromEnum(countries, record.country) : ''}${record.city ? ` / ${getValueFromEnum(cities, record.city)}` : ''} `}</div>
    ),
  },
  {
    title: 'İhbar Tarihi',
    dataIndex: 'notice_date',
    key: 'notice_date',
    sorter: (a, b) =>
      new Date(a.notice_date).getTime() - new Date(b.notice_date).getTime(),
    render: (_, record) => (
      <div>
        {record.notice_date
          ? dayjs(record.notice_date).format('YYYY-MM-DD')
          : ''}
      </div>
    ),
  },
  {
    title: 'Hasar Tarihi',
    dataIndex: 'damage_date',
    key: 'damage_date',
    sorter: (a, b) =>
      new Date(a.damage_date).getTime() - new Date(b.damage_date).getTime(),
    render: (_, record) => (
      <div>
        {record.damage_date
          ? dayjs(record.damage_date).format('YYYY-MM-DD')
          : ''}
      </div>
    ),
  },
  {
    title: 'Muallak Hasar Bedeli',
    dataIndex: 'pending_loss',
    key: 'pending_loss',
    sorter: (a, b) => (a.pending_loss || 0) - (b.pending_loss || 0),
    render: (_: unknown, record: IDataSource) => (
      <div>
        {record.pending_loss} {getValueFromEnum(currencies, record.currency)}
      </div>
    ),
  },
  {
    title: 'Durum',
    dataIndex: 'recent_status',
    key: 'recent_status',
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Durum seçin"
          optionFilterProp="children"
          value={selectedKeys[0]}
          onChange={(value) => {
            setSelectedKeys(value ? [value] : []);
            confirm();
            dispatch(
              tableActions.changeTableSearch({
                key: 'recent_status',
                value: value as string,
              }),
            );
          }}
          options={statusChoices.map((item) => ({
            label: getValueFromEnum(statusChoices, item.key) || '',
            value: item.key,
          }))}
        />
      </div>
    ),
    onFilter: (value, record) => record.recent_status === value,
    sorter: (a, b) => a.recent_status.localeCompare(b.recent_status),
    render: (_: unknown, record: IDataSource) => (
      <Tag color={status_colors[record.recent_status]}>
        {getValueFromEnum(statusChoices, record.recent_status)}
      </Tag>
    ),
  },
  {
    title: 'Açıklama',
    dataIndex: 'description',
    key: 'description',
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (_: unknown, record: IDataSource) => (
      <Tooltip title={record.description}>
        <span>{`${record.description.slice(0, 10)}...`}</span>
      </Tooltip>
    ),
  },
  {
    title: 'Eksper / Raportör',
    dataIndex: 'expert',
    key: 'expert',
    sorter: (a, b) => a.expert.localeCompare(b.expert),
  },
  {
    title: 'İşlem',
    dataIndex: 'action',
    key: 'action',
    render: (_: unknown, record: IDataSource) => (
      <Flex justify="flex-end" gap={14}>
        {record.parent && (
          <AuthorizedIcon
            action="delete"
            model="parentdamagemanagement"
            source={Subtract}
            onClick={() => handleClickRemoveParent(record.parent, record.id)}
          />
        )}
        <Icon
          source={EyeRegularWBlack}
          onClick={() => record?.id && handleClickEye(record.id)}
        />
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              İptal işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => handleClickCancel(record.id)}
        >
          <Icon source={Variant99Black} />
        </Popconfirm>
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              Silme işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => handleClickDelete(record.id)}
        >
          <AuthorizedIcon
            action="delete"
            model="damagemanagement"
            source={TrashSolidBlack}
          />
        </Popconfirm>
        <AuthorizedIcon
          action="change"
          model="damagemanagement"
          source={PenToSquareSolidBlack}
          onClick={() => handleOpenEditModal(record.id)}
        />
      </Flex>
    ),
  },
];
