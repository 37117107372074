import { FC, Key, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Card, Divider, Flex, Tree } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  ProfileModal,
  DeleteModal,
  AuthorizedButton,
  PageTitle,
} from '../../../components';
import { IInitialValues, IRole, IRolePermissions } from './type';
import { ProfileCard } from './components';
import { leftTreeData, rightTreeData } from './const';
import styles from './index.module.scss';
import useHttpService from '../../../services/hook';
import { RequestMethods } from '../../../services/enum';
import { extractKeys } from './helpers';
import { IResponse } from '../../../types';
import { userActions } from '../../../store/reducers/userReducer';
import { useAppDispatch, useAppSelector } from '../../../store';
import { IOrganization } from '../UserSettings/type';
import validationSchema from './validations';

const ProfileSettings: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<
    IRolePermissions | undefined
  >(undefined);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  const executeService = useHttpService();
  const dispatch = useAppDispatch();
  const { role, organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );

  const INITIAL_VALUES: IInitialValues = {
    name: '',
    leftPermissions: [],
    rightPermissions: [],
    organization: organization,
  };

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleOpenModal = () => {
    setSelectedItem(undefined);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => setIsModalOpen(false);
  const handleClickEdit = () => setIsEditModalOpen(true);
  const handleCloseEdit = () => setIsEditModalOpen(false);
  const handleClickDelete = () => setIsDeleteModalOpen(true);

  const onLeftCheck = (
    checkedKeysValue:
      | {
          checked: Key[];
          halfChecked: Key[];
        }
      | Key[],
  ) => formik.setFieldValue('leftPermissions', checkedKeysValue);
  const onRightCheck = (
    checkedKeysValue:
      | {
          checked: Key[];
          halfChecked: Key[];
        }
      | Key[],
  ) => formik.setFieldValue('rightPermissions', checkedKeysValue);

  const handleFetchRoles = async () => {
    try {
      const response = await executeService<IResponse<IRole>>({
        method: RequestMethods.GET,
        url: 'auth/role/',
      });
      if (response && 'results' in response) {
        setRoles(response.results);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeRole = async (id: number) => {
    const selectedItem = await executeService<IRolePermissions>({
      method: RequestMethods.GET,
      url: `auth/role/${id}/`,
    });
    selectedItem && 'id' in selectedItem && setSelectedItem(selectedItem);
  };

  const handleFetchUserPermissions = async () => {
    const response = await executeService<IRolePermissions>({
      method: RequestMethods.GET,
      url: `auth/role/${role.id}/`,
    });
    if (response && 'id' in response) {
      dispatch(userActions.changeUserPermissions(response.permissions));
    }
  };

  const onSubmit = async (values: IInitialValues) => {
    try {
      const { name, leftPermissions, rightPermissions, organization } = values;
      const filteredLeftPermissions = leftPermissions
        .filter((item) => !item.toString().startsWith('T-'))
        .map((item) => Number(item));
      const filteredRightPermissions = rightPermissions
        .filter((item) => !item.toString().startsWith('T-'))
        .map((item) => Number(item));
      const data = {
        name,
        permissions: [...filteredLeftPermissions, ...filteredRightPermissions],
        organization,
      };
      if (selectedItem) {
        await executeService<unknown>({
          method: RequestMethods.POST,
          url: `auth/role/${selectedItem.id}/assign-permissions/`,
          data,
        });
      } else {
        await executeService<unknown>({
          method: RequestMethods.POST,
          url: 'auth/role/',
          data,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsModalOpen(false);
      setIsEditModalOpen(false);
      handleFetchRoles();
      handleFetchUserPermissions();
    }
  };

  const onCancel = () => setIsDeleteModalOpen(false);
  const onConfirm = async () => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `auth/role/${selectedItem?.id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      handleFetchRoles();
      setIsDeleteModalOpen(false);
      setSelectedItem(undefined);
    }
  };

  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    handleFetchOrganizations();
    handleFetchRoles();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const values: IInitialValues = {
        name: selectedItem.external_name,
        leftPermissions: selectedItem.permissions
          .filter((item) => extractKeys(leftTreeData).includes(item.id))
          .map((item) => item.id),
        rightPermissions: selectedItem.permissions
          .filter((item) => extractKeys(rightTreeData).includes(item.id))
          .map((item) => item.id),
        organization: selectedItem.organization,
      };
      formik.setValues(values);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  return (
    <Flex vertical gap={10} className={styles.container}>
      <PageTitle title="Kullanıcı Profilleri & Yetkilendirme" />
      <Flex justify="flex-end">
        <AuthorizedButton
          model="extendedgroup"
          action="add"
          className={styles.addButton}
          onClick={handleOpenModal}
        >
          <PlusOutlined className={styles.buttonIcon} />
          <div className={styles.buttonText}>Yeni Profil Oluştur</div>
        </AuthorizedButton>
      </Flex>
      <Card>
        <Flex className={styles.bottomContainer} justify="space-between">
          <ProfileCard
            selectedItem={selectedItem}
            roles={roles}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
            handleChangeRole={handleChangeRole}
          />
          <Divider type="vertical" className={styles.divider} />
          <Flex justify="space-between" className={styles.treeContainer}>
            <Tree
              checkable
              defaultExpandAll
              disabled={true}
              treeData={leftTreeData}
              className={styles.customCheckbox}
              onCheck={onLeftCheck}
              checkedKeys={formik.values.leftPermissions}
            />
            <Tree
              checkable
              defaultExpandAll
              disabled={true}
              treeData={rightTreeData}
              className={styles.customCheckbox}
              onCheck={onRightCheck}
              checkedKeys={formik.values.rightPermissions}
            />
          </Flex>
        </Flex>
      </Card>
      <ProfileModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Profil Oluşturma"
        subTitle="Yeni Kullanıcı Profili Oluşturma"
        formik={formik}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
        organizations={organizations}
      />
      <ProfileModal
        type="EDIT"
        open={isEditModalOpen}
        title="Profil Güncelleme"
        subTitle="Kullanıcı Profil Güncelleme"
        formik={formik}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEdit}
        organizations={organizations}
      />
      <DeleteModal
        role={selectedItem?.external_name}
        count={selectedItem?.connected_users}
        default={selectedItem?.default}
        open={isDeleteModalOpen}
        handleOk={onConfirm}
        handleCancel={onCancel}
      />
    </Flex>
  );
};

export default ProfileSettings;
