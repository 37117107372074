import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Flex, Input, Typography } from 'antd';
import { INITIAL_VALUES } from './const';
import { IBody } from './type';
import styles from './index.module.scss';
import LoginImg from '../../assets/images/login.jpg';
import Logo from '../../assets/images/logo.svg';
import EyeSolidB from '../../assets/icons/eye-solid-b.svg';
import { RequestMethods } from '../../services/enum';
import UseHttpService from '../../services/hook';
import validationSchema from './validations';

const ResetPassword: FC = () => {
  const location = useLocation();
  const executeService = UseHttpService();
  const navigate = useNavigate();

  const handleSubmit = async (values: IBody) => {
    delete values['password_again'];

    const data = await executeService<{ message: string }>({
      method: RequestMethods.POST,
      url: 'auth/password-reset/confirm/',
      data: values,
    });
    data && 'message' in data && navigate('/login');
  };

  const formik = useFormik<IBody>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (location.search.length) {
      const query = location.search.substring(1);
      if (query) {
        const decoded = atob(query);
        const userId = decoded ? decoded.split('&')[0].split('=')[1] : '';
        const code = decoded ? decoded.split('&')[1].split('=')[1] : '';
        formik.setFieldValue('user_id', userId);
        formik.setFieldValue('code', code);
      }
    }
  }, [location.search]);

  return (
    <Flex justify="space-between">
      <Flex justify="center" className={styles.leftSideContainer}>
        <Flex vertical justify="center" gap={36} className={styles.leftSide}>
          <Flex vertical align="center" gap={10}>
            <img src={Logo} alt="login_logo" width={275} />
            <div className={styles.logoText}>Müşteri Yönetim Paneli</div>
          </Flex>
          <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
            <Flex vertical gap={8}>
              <Typography className={styles.description}>
                Yeni şifrenizi girerek şifre bilginizi değiştirebilirsiniz.
              </Typography>
              <Typography className={styles.fieldLabel}>Yeni Şifre</Typography>
              <Input
                type="password"
                className={styles.fieldInput}
                placeholder="Yeni Şifre"
                prefix={<img src={EyeSolidB} alt="EyeSolidB" />}
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password && (
                <div
                  style={{ color: '#FF0000', fontSize: 12, fontWeight: 400 }}
                >
                  {formik.errors.password}
                </div>
              )}
            </Flex>
            <Flex vertical gap={8}>
              <Typography className={styles.fieldLabel}>
                Yeni Şifre Tekrar
              </Typography>
              <Input
                type="password"
                className={styles.fieldInput}
                placeholder="Yeni Şifre Tekrar"
                prefix={<img src={EyeSolidB} alt="EyeSolidB" />}
                {...formik.getFieldProps('password_again')}
              />
              {formik.touched.password_again &&
                formik.errors.password_again && (
                  <div
                    style={{ color: '#FF0000', fontSize: 12, fontWeight: 400 }}
                  >
                    {formik.errors.password_again}
                  </div>
                )}
            </Flex>
            <Button htmlType="submit" className={styles.button}>
              Gönder
            </Button>
          </form>
        </Flex>
      </Flex>
      <img src={LoginImg} alt="LoginImg" className={styles.image} />
    </Flex>
  );
};

export default ResetPassword;
