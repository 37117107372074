import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  organization: 0,
  goods_type: '',
  goods_quantity: undefined,
  goods_unit: '',
  policy_value: undefined,
  policy_value_unit: '',
  policy_number: '',
  email_permission: false,
  insurance_value: undefined,
  insurance_value_unit: '',
  invoice_date: '',
  invoice_undefined: '',
  expedition_date: '',
  origin_country: '',
  transportation_type: '',
  destination_country: '',
  upload_files: [],
  vehicle_type: '',
  vehicle_info: '',
  description: '',
  recent_status: '',
};
