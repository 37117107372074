import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur.'),
  first_name: Yup.string()
    .required('İsim zorunludur.')
    .min(3, 'Minimum 3 hane olmalıdır')
    .max(20, 'Maksimum 20 hane olmalıdır'),
  last_name: Yup.string()
    .required('Soyisim zorunludur.')
    .min(3, 'Mininmum 3 hane olmalıdır')
    .max(20, 'Maksimum 20 hane olmalıdır'),
  email: Yup.string()
    .required('Email zorunludur.')
    .email('Email formatında olmalıdır'),
  role: Yup.number().notOneOf([0], 'Rol zorunludur.'),
});

export default validationSchema;
