import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Firma zorunludur.')
    .min(3, 'Firma adı en az 3 karakter olmalıdır.')
    .max(20, 'Firma adı en fazla 20 karakter olabilir.'),
  tax_number: Yup.string()
    .required('Vergi numarası zorunludur.')
    .matches(/^[0-9]+$/, 'Vergi numarası sadece rakamlardan oluşmalıdır.'),
  tax_office: Yup.string().required('Vergi dairesi zorunludur.'),
  address: Yup.string()
    .required('Adres zorunludur.')
    .min(3, 'Adres en az 3 karakter olmalıdır.')
    .max(60, 'Adres en fazla 60 karakter olabilir.'),
});

export default validationSchema;
