import { createSlice } from '@reduxjs/toolkit';
import { IInitialState, IAction, IPermission, IUserInfo } from './type';

const initialState: IInitialState = {
  userInfo: {
    id: 0,
    email: '',
    first_name: '',
    last_name: '',
    position: '',
    department: '',
    phone_number: '',
    address: '',
    role: {
      id: 0,
      name: '',
      connected_users: 0,
      created_at: '',
      created_by: '',
      organization: 0,
      external_name: '',
    },
    organization: 0,
    created_at: '',
    created_by: '',
    is_verified: false,
    is_active: false,
  },
  permissions: [],
};

const changeUserInfo = (state: IInitialState, action: IAction<IUserInfo>) => {
  state.userInfo = action.payload;
};

const changeUserPermissions = (
  state: IInitialState,
  action: IAction<IPermission[]>,
) => {
  state.permissions = action.payload;
};

const clearUser = (state: IInitialState) => {
  state.userInfo = initialState.userInfo;
  state.permissions = initialState.permissions;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeUserInfo,
    changeUserPermissions,
    clearUser,
  },
});

export const userActions = userSlice.actions;
const userReducer = userSlice.reducer;
export default userReducer;
