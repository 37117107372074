import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import { IDataSource, IInitialValues } from './type';

export const prepareFetchParameters = (
  dates?: string[],
  search?: ISearch,
  sort?: ISort,
  page?: IPage,
): string => {
  const params = new URLSearchParams();

  page?.size && params.append('limit', page?.size.toString());
  typeof page?.number === 'number' &&
    params.append('offset', (page.size * page.number).toString());
  dates?.[0] && params.append('start_date', dates[0]);
  dates?.[1] && params.append('end_date', dates[1]);
  search?.key && search?.value && params.append(search.key, search.value);
  sort?.key &&
    sort.order &&
    params.append(
      'ordering',
      sort.order === 'ascend' ? sort.key : `-${sort.key}`,
    );

  return params.size ? `?${params.toString()}` : '';
};

export const prepareFormData = (values: IInitialValues): FormData => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    if (key === 'upload_files')
      values[key][0]?.size > 0 && formData.append(key, values[key][0]);
    else if (key === 'email_permission')
      formData.append(key, (!!values[key]).toString());
    else formData.append(key, values[key] || '');
  });
  return formData;
};

export const prepareModalData = (
  item?: IDataSource,
): IInitialValues | undefined => {
  if (item) {
    return {
      id: item.id,
      organization: item.organization,
      goods_type: item.goods_type,
      goods_quantity: item.goods_quantity,
      goods_unit: item.goods_unit,
      policy_value: item.policy_value,
      policy_value_unit: item.policy_value_unit,
      policy_number: item.policy_number,
      insurance_value: item.insurance_value,
      insurance_value_unit: item.insurance_value_unit,
      invoice_date: item.invoice_date,
      invoice_number: item.invoice_number,
      expedition_date: item.expedition_date,
      origin_country: item.origin_country,
      transportation_type: item.transportation_type,
      destination_country: item.destination_country,
      upload_files: [new File([], item.additional_files[0]?.name || '')],
      vehicle_type: item.vehicle_type,
      vehicle_info: item.vehicle_info,
      description: item.description,
      recent_status: item.recent_status,
      email_permission: item.email_permission,
    } as IInitialValues;
  }
};

export const cleanupAfterSubmit = (
  setIsModalOpen: (open: boolean) => void,
  setSelectedItem: (item?: IDataSource) => void,
  formikReset: () => void,
  handleFetchData: () => void,
) => {
  setIsModalOpen(false);
  setSelectedItem(undefined);
  formikReset();
  handleFetchData();
};
