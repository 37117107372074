import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  name: '',
  is_active: true,
  tax_number: '',
  tax_office: '',
  address: '',
};
