import { useAppSelector } from '../../store';

const CheckSibaOrganization = () => {
  const { organization } = useAppSelector(
    (state) => state.userReducer.userInfo,
  );
  return organization === 1;
};

export { CheckSibaOrganization };
