import { FC, PropsWithChildren, ReactElement } from 'react';
import { useAppSelector } from './store';

interface WithAuthorizationProps {
  model: string;
  children: ReactElement;
}

const WithAuthorization: FC<PropsWithChildren<WithAuthorizationProps>> = ({
  model,
  children,
}) => {
  const permissions = useAppSelector((state) => state.userReducer.permissions);

  const isAuthorized = permissions.some(
    (permission) => permission.action === 'view' && permission.model === model,
  );

  if (!isAuthorized) {
    return null;
  }

  return children;
};

export default WithAuthorization;
