import { FC } from 'react';
import { useFormik } from 'formik';
import { Flex, Input } from 'antd';
import { ErrorMessage, ModalDialog } from '..';
import { IProps, IInitialValues } from './type';
import styles from './index.module.scss';
import { INITIAL_VALUES } from './const';
import validationSchema from './validations';
import useHttpService from '../../services/hook';
import { RequestMethods } from '../../services/enum';

const ResetPasswordModal: FC<IProps> = (props) => {
  const executeService = useHttpService();

  const onSubmit = async (values: IInitialValues) => {
    await executeService<unknown>({
      method: RequestMethods.POST,
      url: 'auth/user/change-password/',
      data: values,
    });
    props.handleSubmit();
  };

  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit,
  });
  console.log(formik.values.password === formik.values.password_again);
  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={formik.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical className={styles.container}>
        <Flex vertical gap="small">
          <Input
            placeholder="Eski Şifre"
            type="password"
            {...formik.getFieldProps('old_password')}
          />
          {formik.touched.old_password && formik.errors.old_password && (
            <ErrorMessage>{formik.errors.old_password}</ErrorMessage>
          )}
        </Flex>
        <Flex vertical gap="small">
          <Input
            placeholder="Yeni Şifre"
            type="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password && (
            <ErrorMessage>{formik.errors.password}</ErrorMessage>
          )}
        </Flex>
        <Flex vertical gap="small">
          <Input
            placeholder="Yeni Şifre Tekrar"
            type="password"
            {...formik.getFieldProps('password_again')}
          />
          {formik.touched.password_again && formik.errors.password_again && (
            <ErrorMessage>{formik.errors.password_again}</ErrorMessage>
          )}
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default ResetPasswordModal;
