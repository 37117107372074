import * as Yup from 'yup';

const validationSchemaForLogin = Yup.object().shape({
  email: Yup.string()
    .email('Email formatında olmalıdır')
    .required('E-mail zorunludur'),
  password: Yup.string()
    .required('Şifre zorunludur')
    .min(6, 'Mininmum 6 hane olmalıdır')
    .max(12, 'Maksimum 12 hane olmalıdır'),
});

const validationSchemaForForgotPassword = Yup.object().shape({
  email: Yup.string()
    .email('Email formatında olmalıdır')
    .required('E-mail zorunludur'),
});

export { validationSchemaForLogin, validationSchemaForForgotPassword };
