import axios, { CreateAxiosDefaults } from 'axios';
import { ERROR_CODES, BASE_URL } from './const';
import { notification } from 'antd';
import InfoRed from '../assets/icons/info-red.svg';
import InfoGreen from '../assets/icons/Variant97.svg';
import { RequestMethods } from './enum';

const axiosConfig: CreateAxiosDefaults<any> = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
};

const axiosInstance = axios.create(axiosConfig);

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access');
    if (token) {
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.config.method?.toUpperCase() !== RequestMethods.GET) {
      notification.success({
        message: 'BAŞARILI',
        description: 'İşleminiz başarıyla gerçekleştirilmiştir.',
        icon: <img width={20} src={InfoGreen} alt="InfoGreen" />,
      });
    }
    return response;
  },
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;

    if (status === 400 || status === 500) {
      notification.error({
        message: 'HATA',
        description: error.response.data.user_message,
        icon: <img width={20} src={InfoRed} alt="InfoRed" />,
      });
    }

    if (status === 401) {
      const refresh = localStorage.getItem('refresh');
      const response = await axios<{ access: string }>({
        baseURL: BASE_URL,
        method: RequestMethods.POST,
        url: 'auth/token/refresh/',
        data: { refresh },
      });

      if (response.data && 'access' in response.data) {
        localStorage.setItem('access', response.data.access);

        if (originalRequest.headers) {
          originalRequest.headers.Authorization = `Bearer ${response.data.access}`;
        }

        return axiosInstance(originalRequest);
      }
    }
    !!status && console.error(ERROR_CODES[status]);
    return Promise.reject(error);
  },
);

export default axiosInstance;
