import { FC, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Flex, Input, Typography } from 'antd';
import { INITIAL_VALUES } from './const';
import { IBody, IResponse } from './type';
import styles from './index.module.scss';
import LoginImg from '../../assets/images/login.jpg';
import Logo from '../../assets/images/logo.svg';
import EyeSolidB from '../../assets/icons/eye-solid-b.svg';
import UserSolidB from '../../assets/icons/user-solid-b.svg';
import { login } from '../../helpers/Auth';
import { RequestMethods } from '../../services/enum';
import UseHttpService from '../../services/hook';
import {
  validationSchemaForForgotPassword,
  validationSchemaForLogin,
} from './validations';

const Login: FC = () => {
  const [isForgotPasswordDisplayed, setIsForgotPasswordDisplayed] =
    useState<boolean>(false);

  const executeService = UseHttpService();

  const handleSubmit = async (values: IBody) => {
    if (!isForgotPasswordDisplayed) {
      const response = await executeService<IResponse>({
        method: RequestMethods.POST,
        url: 'auth/token/',
        data: values,
      });
      response && 'access' in response && login(response);
    } else {
      const response = await executeService<IResponse>({
        method: RequestMethods.POST,
        url: 'auth/password-reset/send/',
        data: { email: values.email },
      });
      response && 'email' in response && alert(123123);
    }
  };

  const handleClickLink = () => setIsForgotPasswordDisplayed((prev) => !prev);

  const formik = useFormik<IBody>({
    initialValues: INITIAL_VALUES,
    validationSchema: !isForgotPasswordDisplayed
      ? validationSchemaForLogin
      : validationSchemaForForgotPassword,
    onSubmit: handleSubmit,
  });

  return (
    <Flex justify="space-between">
      <Flex justify="center" className={styles.leftSideContainer}>
        <Flex vertical justify="center" gap={36} className={styles.leftSide}>
          <Flex
            vertical
            align="center"
            gap={10}
            className={styles.logoContainer}
          >
            <img src={Logo} alt="login_logo" width={275} />
            <div className={styles.logoText}>Müşteri Yönetim Paneli</div>
          </Flex>
          <form className={styles.formContainer} onSubmit={formik.handleSubmit}>
            {isForgotPasswordDisplayed ? (
              <Flex vertical gap={8}>
                <Typography className={styles.forgotPasswordDescription}>
                  Şifrenizi sıfırlamak için mail adresinizi girip size iletilen
                  maildeki yönergeleri takip edebilirsiniz.
                </Typography>
                <Typography className={styles.fieldLabel}>Mail</Typography>
                <Input
                  className={styles.fieldInput}
                  placeholder="Mail"
                  prefix={<img src={UserSolidB} alt="UserSolidB" />}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div
                    style={{ color: '#FF0000', fontSize: 12, fontWeight: 400 }}
                  >
                    {formik.errors.email}
                  </div>
                )}
              </Flex>
            ) : (
              <>
                <Flex vertical gap={8}>
                  <Typography className={styles.fieldLabel}>E-mail</Typography>
                  <Input
                    className={styles.fieldInput}
                    placeholder="E-mail"
                    prefix={<img src={UserSolidB} alt="UserSolidB" />}
                    {...formik.getFieldProps('email')}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div
                      style={{
                        color: '#FF0000',
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.email}
                    </div>
                  )}
                </Flex>
                <Flex vertical gap={8}>
                  <Typography className={styles.fieldLabel}>Şifre</Typography>
                  <Input
                    type="password"
                    className={styles.fieldInput}
                    placeholder="Şifre"
                    prefix={<img src={EyeSolidB} alt="EyeSolidB" />}
                    {...formik.getFieldProps('password')}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div
                      style={{
                        color: '#FF0000',
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      {formik.errors.password}
                    </div>
                  )}
                </Flex>
              </>
            )}
            <Flex justify="flex-end">
              <div className={styles.link} onClick={handleClickLink}>
                {!isForgotPasswordDisplayed ? 'Şifremi Unuttum' : 'Giriş Yap'}
              </div>
            </Flex>
            <Button htmlType="submit" className={styles.button}>
              {!isForgotPasswordDisplayed ? 'Giriş Yap' : 'Gönder'}
            </Button>
          </form>
        </Flex>
      </Flex>
      <img src={LoginImg} alt="LoginImg" className={styles.image} />
    </Flex>
  );
};

export default Login;
