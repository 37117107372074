import { Flex, Typography } from 'antd';
import { FC } from 'react';
import Logo from '../../assets/images/logo.svg';
import { useAppSelector } from '../../store';

const Mainpage: FC = () => {
  const currentUser = useAppSelector((state) => state.userReducer.userInfo);

  return (
    <Flex
      vertical
      justify="center"
      align="center"
      style={{
        height: 'calc(100vh - 66px)',
        backgroundColor: '#001628FA',
        width: '100%',
      }}
    >
      <Flex vertical align="center">
        <img src={Logo} alt="Logo" style={{ width: 375 }} />
        <Typography.Title
          level={2}
          style={{
            fontFamily: "'SF Pro Display', sans-serif",
            color: '#FFFFFF',
            fontStyle: 'italic',
          }}
        >
          Welcome to Siba Sigorta Portal
        </Typography.Title>
      </Flex>
      {currentUser.last_name.length && (
        <Typography.Title
          level={3}
          style={{
            fontFamily: "'SF Pro Display', sans-serif",
            color: '#FFFFFF',
          }}
        >
          Hello, {currentUser.last_name}
        </Typography.Title>
      )}
    </Flex>
  );
};

export default Mainpage;
