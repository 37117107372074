import { IDataSource } from './type';
import styles from './index.module.scss';
import { AuthorizedIcon } from '../../../components';
import TrashSolidBlack from '../../../assets/icons/trash-solid-b-black.svg';
import PenToSquareSolidBlack from '../../../assets/icons/pen-to-square-solid-w-black.svg';
import { Flex, Popconfirm, Tag } from 'antd';
import dayjs from 'dayjs';
import { ColumnType } from 'antd/es/table';

export const getColumns = (
  handleClickDelete: (id: number) => Promise<void>,
  handleOpenEditModal: (id: number) => Promise<void>,
): Array<ColumnType<IDataSource> & { searchable?: boolean }> => [
  {
    title: 'Firma',
    dataIndex: 'name',
    key: 'name',
    searchable: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: 'Vergi Numarası',
    dataIndex: 'tax_number',
    key: 'tax_number',
    sorter: (a, b) => a.tax_number.localeCompare(b.tax_number),
  },
  {
    title: 'Vergi Dairesi',
    dataIndex: 'tax_office',
    key: 'tax_office',
    sorter: (a, b) => a.tax_office.localeCompare(b.tax_office),
  },
  {
    title: 'Adres',
    dataIndex: 'address',
    key: 'address',
    sorter: (a, b) => a.address.localeCompare(b.address),
  },
  {
    title: 'Kayıt Tarihi',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: (a, b) => a.created_at.localeCompare(b.created_at),
    render: (_, record) => (
      <div>
        {record.created_at
          ? dayjs(record.created_at).format('YYYY-MM-DD')
          : null}
      </div>
    ),
  },
  {
    title: 'Oluşturan Kullanıcı',
    dataIndex: 'created_by',
    key: 'created_by',
    sorter: (a, b) => a.created_by - b.created_by,
  },
  {
    title: 'Durum',
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: (a, b) => Number(a.is_active) - Number(b.is_active),
    render: (_: unknown, record: IDataSource) => (
      <Tag color={record.is_active ? '#87A330' : '#D6222A'}>
        {record.is_active ? 'Aktif' : 'Silindi'}
      </Tag>
    ),
  },
  {
    title: 'İşlem',
    dataIndex: 'action',
    key: 'action',
    render: (_: unknown, record: IDataSource) => (
      <Flex justify="flex-end" gap={14}>
        <Popconfirm
          title={
            <div className={styles.popconfirmTitle}>
              Silme işlemini onaylıyor musunuz?
            </div>
          }
          overlayInnerStyle={{
            width: 150,
            borderColor: '#D6222A',
            borderWidth: 1,
            borderStyle: 'solid',
          }}
          okText="Onayla"
          cancelText="İptal"
          icon={null}
          color="#FAFAFA"
          okButtonProps={{ danger: true, style: { width: '45%' } }}
          cancelButtonProps={{ danger: true, style: { width: '45%' } }}
          onConfirm={() => record.id && handleClickDelete(record.id)}
        >
          <AuthorizedIcon
            action="delete"
            model="organization"
            source={TrashSolidBlack}
          />
        </Popconfirm>
        <AuthorizedIcon
          action="change"
          model="organization"
          source={PenToSquareSolidBlack}
          onClick={() => record?.id && handleOpenEditModal(record.id)}
        />
      </Flex>
    ),
  },
];
