import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  organization: 0,
  policy_type: '',
  policy_number: '',
  upload_files: new File([''], ''),
  start_date: '',
  end_date: '',
  email_permission: false,
};
