import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Şifre zorunludur')
    .min(6, 'Mininmum 6 hane olmalıdır'),
  password_again: Yup.string()
    .required('Şifre zorunludur')
    .oneOf([Yup.ref('password'), ''], 'Şifreler aynı olmalıdır')
    .min(6, 'Mininmum 6 hane olmalıdır'),
});

export default validationSchema;
