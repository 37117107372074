import { FC } from 'react';
import { Flex, Input } from 'antd';
import { ErrorMessage, LabelTitle, ModalDialog } from '..';
import { IProps } from './type';
import styles from '../../assets/styles/index.module.scss';
import TextArea from 'antd/es/input/TextArea';

const OrganizationModal: FC<IProps> = (props) => {
  const { getFieldProps, errors, touched } = props.formik;

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Flex align="flex-start" justify="space-between">
          <LabelTitle>Firma Bilgileri</LabelTitle>
          <Flex vertical gap="small">
            <Input placeholder="Firma Adı" {...getFieldProps('name')} />
            {touched.name && errors.name && (
              <ErrorMessage>{errors.name}</ErrorMessage>
            )}
          </Flex>
        </Flex>
        <Flex vertical gap="small">
          <TextArea placeholder="Adres" {...getFieldProps('address')} />
          {touched.address && errors.address && (
            <ErrorMessage>{errors.address}</ErrorMessage>
          )}
        </Flex>
        <Flex gap="small" vertical>
          <LabelTitle>Kullanıcı Bilgileri</LabelTitle>
          <Flex justify="space-between" gap="small">
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Input
                placeholder="Vergi Numarası"
                {...getFieldProps('tax_number')}
              />
              {touched.tax_number && errors.tax_number && (
                <ErrorMessage>{errors.tax_number}</ErrorMessage>
              )}
            </Flex>
            <Flex vertical gap="small" className={styles.widthHalf}>
              <Input
                placeholder="Vergi Dairesi"
                {...getFieldProps('tax_office')}
              />
              {touched.tax_office && errors.tax_office && (
                <ErrorMessage>{errors.tax_office}</ErrorMessage>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ModalDialog>
  );
};

export default OrganizationModal;
