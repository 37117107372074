import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Flex, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { getItems } from './helpers';
import styles from './index.module.scss';
import { logout } from '../../helpers/Auth';
import { useAppDispatch, useAppSelector } from '../../store';
import { userActions } from '../../store/reducers/userReducer';

const Sidemenu: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const permissions = useAppSelector((state) => state.userReducer.permissions);

  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const handleMenuClick = ({ key }: MenuInfo) => {
    key && navigate(key);
  };

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (latestOpenKey) {
      setOpenKeys([latestOpenKey]);
    } else {
      setOpenKeys([]);
    }
  };

  const handleClickLogout = () => {
    dispatch(userActions.clearUser());
    logout();
  };

  return (
    <Flex vertical justify="space-between" className={styles.container}>
      <Menu
        defaultSelectedKeys={['/']}
        mode="inline"
        theme="dark"
        selectedKeys={[location.pathname]}
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        inlineCollapsed={false}
        expandIcon={<CaretDownOutlined />}
        items={getItems(
          permissions.filter((permission) => permission.action === 'view'),
        )}
        className={styles.menu}
        onClick={handleMenuClick}
      />
      <div className={styles.logoutButtonContainer}>
        <Button onClick={handleClickLogout} className={styles.logoutButton}>
          Çıkış Yap
        </Button>
      </div>
    </Flex>
  );
};

export default Sidemenu;
