import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur'),
  insurance_value: Yup.number()
    .typeError('Emtia bedeli nümerik olmalıdır')
    .required('Emtia bedeli zorunludur'),
  insurance_value_unit: Yup.string().required('Para birimi zorunludur'),
  transportation_type: Yup.string().required('Sevkiyat tipi zorunludur'),
  expedition_date: Yup.string().required('Sevk tarihi zorunludur'),
});

export default validationSchema;
