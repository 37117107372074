import { FC } from 'react';
import { Flex } from 'antd';
import { IProps } from './type';
import styles from './index.module.scss';

const PageTitle: FC<IProps> = ({ title }) => {
  return (
    <Flex justify="center" align="center" gap={10} className={styles.container}>
      <div className={styles.leftLine} />
      <div className={styles.name}>{title}</div>
      <div className={styles.rightLine} />
    </Flex>
  );
};

export default PageTitle;
