import { FC } from 'react';
import { Flex, Typography } from 'antd';
import { DataTable, ModalDialog } from '../';
import { IDataSource, IProps } from './type';
import styles from './index.module.scss';
import { getColumns } from './columns';

const BindParentModal: FC<IProps> = (props) => {
  const columns = getColumns(
    props.branches,
    props.cities,
    props.countries,
    props.damageTypes,
    props.organizations,
  );

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex gap="middle" vertical>
        <Typography className={styles.labelTitle}>Seçili Dosyalar</Typography>
        <DataTable<IDataSource>
          columns={columns}
          dataSource={props.dataSource}
          isPaginationDisplayed={false}
          statusChoices={props.statusChoices}
        />
      </Flex>
    </ModalDialog>
  );
};

export default BindParentModal;
