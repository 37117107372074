import { FC, useEffect, useState } from 'react';
import { Table, Typography, Flex, Select } from 'antd';
import styles from './index.module.scss';
import { IDataItem, IProps } from './type';
import ModalDialog from '../ModalDialog';
import { transformToData } from './helpers';
import columns from './columns';
import { CheckSibaOrganization } from '../../helpers/Organization';

const SummarySheetModal: FC<IProps> = (props) => {
  const [data, setData] = useState<IDataItem[]>([]);

  useEffect(() => {
    if (props.summaryTable.length) {
      const data = transformToData(props.summaryTable, props.statusChoices);
      setData(data);
    } else {
      setData([]);
    }
  }, [props.summaryTable]);

  return (
    <ModalDialog
      title={props.title}
      subTitle={props.subTitle}
      open={props.open}
      isButtonsDisplayed={false}
      handleOk={props.handleSubmit}
      handleCancel={props.handleCancel}
    >
      <Flex vertical gap={20}>
        <Flex vertical gap={2}>
          <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
          <Select
            className={styles.input}
            placeholder="Firma Seçiniz"
            disabled={!CheckSibaOrganization()}
            options={props.organizations.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            onChange={props.handleChangeSummaryTable}
          />
        </Flex>
        <Table
          dataSource={data}
          columns={columns}
          pagination={false}
          className={styles.table}
        />
      </Flex>
      <Flex vertical gap={10} className={styles.noteContainer}>
        <div className={styles.note}>
          * Dosya takiplerinde hasar tarihi değil, ihbar tarihi baz
          alınmaktadır.
        </div>
        <div className={styles.note}>
          ** Yurtiçi hasarlarında tek bir hasara bağlı demirbaş, emtia, ecs
          hasarları için ayrı ayrı dosya açtırılmaktadır. Aynı hasara bağlı
          farklı hasar dosyaları tek bir hasar dosyası olarak baz alınmaktadır.
        </div>
      </Flex>
      <Typography.Text className={styles.timestamp}>
        24/06/2024 - 15:12
      </Typography.Text>
    </ModalDialog>
  );
};

export default SummarySheetModal;
