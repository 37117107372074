import { FC, useEffect, useState } from 'react';
import Logo from '../../assets/images/logo.svg';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Divider, Dropdown, Flex } from 'antd';
import ResetPasswordModal from '../ResetPasswordModal';
import { logout } from '../../helpers/Auth';
import useHttpService from '../../services/hook';
import { IResponse } from '../../types';
import { RequestMethods } from '../../services/enum';
import { IOrganization } from './type';
import { useAppDispatch, useAppSelector } from '../../store';
import { getItems } from './helpers';
import { userActions } from '../../store/reducers/userReducer';

const Header: FC = () => {
  const navigate = useNavigate();
  const executeService = useHttpService();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userReducer.userInfo);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [organizationName, setOrganizationName] = useState<string>('');

  const handleClickLogo = () => navigate('/');
  const handleClickResetPassword = () => setIsModalOpen(true);
  const handleCloseResetPassword = () => {
    setIsModalOpen(false);
    localStorage.removeItem('first_login');
  };
  const handleClickLogout = () => {
    dispatch(userActions.clearUser());
    logout();
  };

  const items = getItems(handleClickResetPassword, handleClickLogout);

  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  useEffect(() => {
    if (organizations && userInfo.role.organization) {
      const organizationName = organizations.find(
        (item) => item.id === userInfo.role.organization,
      )?.name;
      organizationName && setOrganizationName(organizationName);
    }
  }, [organizations, userInfo.role.organization]);

  useEffect(() => {
    handleFetchOrganizations();
  }, []);

  useEffect(() => {
    const isFirstLogin = localStorage.getItem('first_login') === '1';
    setIsModalOpen(isFirstLogin);
  }, [localStorage.getItem('first_login')]);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.headerLogoContainer} onClick={handleClickLogo}>
        <img alt="logo" src={Logo} />
      </div>
      <Flex align="center" gap={14} className={styles.rightSide}>
        <Divider type="vertical" className={styles.verticalLine} />
        <div className={styles.content}>{organizationName}</div>
        <Divider type="vertical" className={styles.verticalLine} />
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          placement="bottomRight"
          rootClassName={styles.dropdown}
        >
          <Flex align="center" gap={14}>
            <div className={styles.content}>
              {userInfo.first_name} {userInfo.last_name}
            </div>
            <Flex
              justify="center"
              align="center"
              className={styles.firstLetterContainer}
            >
              <div className={styles.content}>{userInfo.first_name?.[0]}</div>
            </Flex>
          </Flex>
        </Dropdown>
      </Flex>
      <ResetPasswordModal
        title="Şifre Değiştirme"
        subTitle="Şifre Değiştirme"
        open={isModalOpen}
        handleSubmit={handleCloseResetPassword}
        handleCancel={handleCloseResetPassword}
      />
    </header>
  );
};

export default Header;
