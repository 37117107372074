import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required('İsim alanı boş bırakılamaz')
    .min(3, 'Mininmum 3 hane olmalıdır')
    .max(60, 'Maksimum 60 hane olmalıdır'),
  organization: Yup.number().test(
    'is-not-zero',
    'Firma boş olamaz',
    (value) => value !== 0,
  ),
  leftPermissions: Yup.array().test(
    'left-or-right-not-empty',
    'Yetki alanları boş olamaz',
    function (value) {
      const { rightPermissions } = this.parent;
      return (
        (value && value.length > 0) ||
        (rightPermissions && rightPermissions.length > 0)
      );
    },
  ),
  rightPermissions: Yup.array().test(
    'left-or-right-not-empty',
    'Yetki alanları boş olamaz',
    function (value) {
      const { leftPermissions } = this.parent;
      return (
        (value && value.length > 0) ||
        (leftPermissions && leftPermissions.length > 0)
      );
    },
  ),
});

export default validationSchema;
