import { IErrorCodes } from './type';

export const BASE_URL: string = 'https://alpha.sibaportal.com/api/v1/';

export const ERROR_CODES: IErrorCodes = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Server Internal Error',
};

export const GENERAL_ERROR_MESSAGE = 'Beklenmeyen bir hata oluştu';
