import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import { IDataSource, IInitialValues } from './type';

export const prepareFetchParameters = (
  isExpired?: boolean,
  dates?: string[],
  search?: ISearch,
  sort?: ISort,
  page?: IPage,
): string => {
  const params = new URLSearchParams();

  page?.size && params.append('limit', page?.size.toString());
  typeof page?.number === 'number' &&
    params.append('offset', (page.size * page.number).toString());
  dates?.[0] && params.append('start_date', dates[0]);
  dates?.[1] && params.append('end_date', dates[1]);
  search?.key && search?.value && params.append(search.key, search.value);
  sort?.key &&
    sort.order &&
    params.append(
      'ordering',
      sort.order === 'ascend' ? sort.key : `-${sort.key}`,
    );
  isExpired && params.append('expired', 'false');

  return params.size ? `?${params.toString()}` : '';
};

export const prepareFormData = (values: IInitialValues): FormData => {
  const formData = new FormData();
  values.upload_files.size > 0 &&
    formData.append('upload_files', values.upload_files);
  formData.append('organization', values.organization.toString());
  formData.append('policy_number', values.policy_number);
  formData.append('start_date', values.start_date);
  formData.append('end_date', values.end_date);
  formData.append('policy_type', values.policy_type);
  formData.append('email_permission', (!!values.email_permission).toString());

  return formData;
};

export const cleanupAfterSubmit = (
  setIsModalOpen: (open: boolean) => void,
  setSelectedPolicy: (policy?: IDataSource) => void,
  formikReset: () => void,
  handleFetchData: () => void,
) => {
  setIsModalOpen(false);
  setSelectedPolicy(undefined);
  formikReset();
  handleFetchData();
};

export const setFormForSelectedPolicy = (
  selectedItem: IDataSource,
): IInitialValues => {
  return {
    organization: selectedItem.organization,
    policy_type: selectedItem.policy_type,
    policy_number: selectedItem.policy_number,
    upload_files: new File(
      [''],
      selectedItem.additional_files[selectedItem.additional_files.length - 1]
        ?.name || '',
    ),
    start_date: selectedItem.start_date,
    end_date: selectedItem.end_date,
    email_permission: selectedItem.email_permission,
  };
};
