import * as Yup from 'yup';

const validationSchemaForCreate = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur'),
  branch: Yup.array().min(1, 'Branş zorunludur'),
  upload_files: Yup.array()
    .min(1, 'Ek dosya zorunludur')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Ek dosya zorunludur')
          .min(1, 'Ek dosya zorunludur'),
      }),
    ),
});

const validationSchemaForEdit = Yup.object().shape({
  organization: Yup.number().notOneOf([0], 'Firma zorunludur'),
  upload_files: Yup.array()
    .min(1, 'Ek dosya zorunludur')
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Ek dosya zorunludur')
          .min(1, 'Ek dosya zorunludur'),
      }),
    ),
});

export { validationSchemaForCreate, validationSchemaForEdit };
