import { IInitialValues } from './type';

export const INITIAL_VALUES: IInitialValues = {
  id: undefined,
  upload_files: [],
  branch: [],
  email_permission: false,
  organization: 0,
  policy_number: '',
  start_date: '',
  end_date: '',
  description: '',
  license_plate: '',
  recent_status: '',
};
