import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { PlusOutlined } from '@ant-design/icons';
import {
  DataTable,
  AbroadInsuranceModal,
  AuthorizedButton,
  PageTitle,
  Container,
} from '../../../components';
import { tableActions } from '../../../store/reducers/tableReducer';
import { IDataSource, IInitialValues, IOrganization } from './type';
import styles from './index.module.scss';
import { INITIAL_VALUES } from './const';
import { useAppDispatch, useAppSelector } from '../../../store';
import useHttpService from '../../../services/hook';
import { getColumns } from './columns';
import validationSchema from './validations';
import {
  IPage,
  ISearch,
  ISort,
} from '../../../store/reducers/tableReducer/type';
import { RequestMethods } from '../../../services/enum';
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  prepareFormData,
  prepareModalData,
} from './helpers';
import { IExpandedRow } from '../../../components/DataTable/type';
import { IEnum } from '../../../services/type';
import { IResponse } from '../../../types';
import { Flex } from 'antd';

const AbroadInsurance: FC = () => {
  const search = useAppSelector((state) => state.tableReducer.search);
  const sort = useAppSelector((state) => state.tableReducer.sort);
  const page = useAppSelector((state) => state.tableReducer.page);
  const dispatch = useAppDispatch();
  const executeService = useHttpService();

  // State
  const [selectedItem, setSelectedItem] = useState<IDataSource | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);
  const [branches, setBranches] = useState<IEnum[]>([]);
  const [countries, setCountries] = useState<IEnum[]>([]);
  const [statusChoices, setStatusChoices] = useState<IEnum[]>([]);

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IResponse<IOrganization>>({
      method: RequestMethods.GET,
      url: `auth/organization/`,
    });
    if (response && 'results' in response) {
      setOrganizations(response.results);
    }
  };

  const handleFetchEnums = async () => {
    const response = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-abroad-insurance-branches/`,
    });
    if (response && Array.isArray(response)) {
      setBranches(response);
    }

    const response2 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-countries/`,
    });
    if (response2 && Array.isArray(response2)) {
      setCountries(response2);
    }

    const response3 = await executeService<IEnum[]>({
      method: RequestMethods.GET,
      url: `enums/enums/get-all-status-choices/`,
    });
    if (response3 && Array.isArray(response3)) {
      setStatusChoices(response3);
    }
  };

  const handleFetchData = async (
    search?: ISearch,
    sort?: ISort,
    page?: IPage,
  ) => {
    const parameters = prepareFetchParameters(search, sort, page);
    const response = await executeService<IResponse<IDataSource>>({
      method: RequestMethods.GET,
      url: `main-insurances/abroad-insurance/${parameters}`,
    });
    if (response && 'results' in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      const results = response.results.reduce<IDataSource[]>(
        (prev: IDataSource[], cur: IDataSource) => {
          return [...prev, { ...cur, key: cur.id }];
        },
        [],
      );
      setDataSource(results);
    }
  };

  const handleClickEye = async (id: number) => {
    dispatch(tableActions.changeTableRow(id));
    const expandedRows = await executeService<IExpandedRow[]>({
      method: RequestMethods.GET,
      url: `main-insurances/abroad-insurance/${id}/get-latest-processes/`,
    });
    expandedRows &&
      Array.isArray(expandedRows) &&
      setExpandedRows(expandedRows);
  };

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleOpenEditModal = async (id: number) => {
    const selectedItem = await executeService<IDataSource>({
      method: RequestMethods.GET,
      url: `main-insurances/abroad-insurance/${id}/`,
    });
    selectedItem && 'id' in selectedItem && setSelectedItem(selectedItem);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) =>
    formik.setFieldValue('upload_files', e.target.files);

  const handleClickCancel = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('status', 'cancel_expected');
      await executeService<unknown>({
        method: RequestMethods.POST,
        url: `main-insurances/abroad-insurance/${id}/update-status/`,
        data: formData,
        contentType: 'multipart/form-data',
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({
        method: RequestMethods.DELETE,
        url: `main-insurances/abroad-insurance/${id}/`,
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
    }
  };

  const handleSubmit = async (values: IInitialValues) => {
    try {
      const formData = prepareFormData(values);

      if (selectedItem) {
        const statusFormData = new FormData();
        statusFormData.append('status', values.recent_status);
        await executeService<IDataSource>({
          method: RequestMethods.PUT,
          url: `main-insurances/abroad-insurance/${selectedItem.id}/`,
          data: formData,
          contentType: 'multipart/form-data',
        });
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: `main-insurances/abroad-insurance/${selectedItem.id}/update-status/`,
          data: statusFormData,
          contentType: 'multipart/form-data',
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: 'main-insurances/abroad-insurance/',
          data: formData,
          contentType: 'multipart/form-data',
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(
        setIsModalOpen,
        setSelectedItem,
        formik.resetForm,
        handleFetchData,
      );
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(
    handleClickEye,
    handleOpenEditModal,
    handleClickDelete,
    handleClickCancel,
    organizations,
    branches,
    countries,
    statusChoices,
    dispatch,
  );

  // Form Management
  const formik = useFormik<IInitialValues>({
    initialValues: INITIAL_VALUES,
    validationSchema,
    onSubmit: handleSubmit,
  });

  // Effects
  useEffect(() => {
    handleFetchOrganizations();
    handleFetchEnums();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const result = prepareModalData(selectedItem);
      result && formik.setValues(result);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  useEffect(() => {
    handleFetchData(search, sort, page);
  }, [search.key, search.value, sort.key, sort.order, page.number, page.size]);

  // JSX Return
  return (
    <Container>
      <PageTitle title="Yurtdışı Poliçeler" />
      <Flex vertical gap="middle">
        <Flex justify="flex-end">
          <AuthorizedButton
            model="abroadinsurance"
            action="add"
            className={styles.button}
            onClick={handleOpenModal}
          >
            <PlusOutlined className={styles.buttonIcon} />
            <div className={styles.buttonText}>Yeni Poliçe Talebi</div>
          </AuthorizedButton>
        </Flex>
        <DataTable<IDataSource>
          scrollable
          columns={columns}
          dataSource={dataSource}
          expandedRows={expandedRows}
          statusChoices={statusChoices}
        />
      </Flex>
      <AbroadInsuranceModal
        type="CREATE"
        open={isModalOpen}
        title="Ana Sigortalar ~ Yurtdışı Sigortalar Poliçe Ekleme"
        subTitle="Yeni Yurtdışı Sigortalar Poliçesi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
        countries={countries}
        branches={branches}
        statusChoices={statusChoices}
      />
      <AbroadInsuranceModal
        type="EDIT"
        open={!!selectedItem}
        title="Ana Sigortalar ~ Yurtdışı Sigortalar Poliçe Güncelleme"
        subTitle="Yeni Sigortalar Poliçesi Güncelleme"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
        countries={countries}
        branches={branches}
        statusChoices={statusChoices}
      />
    </Container>
  );
};

export default AbroadInsurance;
