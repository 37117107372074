import { FC } from 'react';
import { useAppSelector } from '../../store';
import { AuthorizedIconProps } from './type';
import Icon from '../Icon';

const AuthorizedIcon: FC<AuthorizedIconProps> = ({
  action,
  model,
  source,
  onClick,
}) => {
  const permissions = useAppSelector((state) => state.userReducer.permissions);
  const hasPermission = permissions.some(
    (permission) => permission.model === model && permission.action === action,
  );

  if (!hasPermission) {
    return null;
  }

  return <Icon source={source} onClick={onClick} />;
};

export default AuthorizedIcon;
