import { FC, PropsWithChildren } from 'react';
import { Flex } from 'antd';
import styles from './index.module.scss';

const Container: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Flex vertical gap={40} className={styles.container}>
      {children}
    </Flex>
  );
};

export default Container;
